// Fonts
$font-family: "Graphik" !default;
$font-regular: "Graphik Regular", arial, helvetica, sans-serif;
$font-light: "Graphik Light", arial, helvetica, sans-serif;
$font-semibold: "Graphik Semibold", arial, arial, helvetica, sans-serif;
$font-medium: "Graphik Medium", arial, arial, helvetica, sans-serif;
// NavBar
$top-navbar-colour: #cccbce;
$mid-navbar-colour: #bac3ca;
$mid-navbar-hover-colour: #ffffff;
$mid-navbar-active-colour: #ffffff;
$mid-navbar-height: 36px;
$bottom-navbar-colour: #ffffff;
$bottom-navbar-hover-colour: #eeeeee;
$bottom-navbar-active-colour: #ffffff;
$bottom-navbar-height: 32px;
// Colours
$dark-colour-default: #000000;
$medium-default: #3e4851;
$light-colour-default: #bac3ca;
$link-colour: #a100ff;
$link-colour-hover: #6805a1;
$positive-colour: #009a8b;
$negative-colour: #a72118;
$cell-edit: #3e90ce;
$cell-edit-invalid: #660000;