
@import "./variables";

.negative {
    color: $negative-colour;
}

.positive {
    color: $positive-colour;
}

.char-1,
.char-2,
.char-3,
.char-4,
.char-5,
.char-6,
.char-7,
.char-8,
.char-9 {
    font-size: 2.125em !important;
}

.char-10,
.char-11,
.char-12,
.char-13,
.char-14 {
    font-size: 1.525em !important;
}

.char-15,
.char-16,
.char-17,
.char-18,
.char-19 {
    font-size: 0.825em !important;
}

.char-20,
.char-21 {
    font-size: 0.975em !important;
}

.item-char-1,
.item-char-2,
.item-char-3,
.item-char-4,
.item-char-5,
.item-char-6,
.item-char-7,
.item-char-8,
.item-char-9,
.item-char-10,
.item-char-11,
.item-char-12,
.item-char-13 {
    font-size: 0.75em !important;
}

.item-char-14,
.item-char-15,
.item-char-16,
.item-char-17,
.item-char-18,
.item-char-19,
.item-char-20,
.item-char-21 {
    position: relative;
    top: 2px;
    font-size: 0.6125em !important;
}
