/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "./styles/variables";
@import "./styles/number-formats";
@import "../../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
@import "material-icons/iconfont/material-icons.scss";
/* Font-awesome */
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';


body {
    background-color: #f2f2f2;
    color: $dark-colour-default;
    font-family: $font-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    overflow: auto;
    text-align: left;
    padding: 0;
    margin: 0;
}

ngb-tooltip-window {

    filter: drop-shadow(rgba(0, 0, 0, 0.5) 0 2px 10px);
    opacity: 1 !important;

    > .tooltip-inner {
        background-color: #f2f2f2;
        color: $dark-colour-default;
        font-family: $font-regular;
        font-size: 11px;
    }

}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f2f2f2;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f2f2f2;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f2f2f2;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f2f2f2;
}

.btn {
    &:focus,
    &:hover {
        text-decoration: none;
        box-shadow: none;
    }

    &.btn-primary {
        font-family: $font-medium;
        font-size: 16px;
        background-color: $link-colour;
        border-color: $link-colour;

        &:hover {
            background-color: $link-colour-hover;
        }
    }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: $link-colour;
    border-color: $link-colour;
}

.dropdown-item.active,
.dropdown-item:active {
    color: $dark-colour-default;
    text-decoration: none;
    background-color: #e9ecef;
}

.modal {
    z-index: 1055;
    &.app-message-dialog {
        z-index: 1055;
        &.show {
            z-index: 1055;
            transition: transform 0.3s ease-out;
            transform: translate(0, -50px);
        }
    }

    &:not(.app-message-dialog) {
        z-index: 1055;
        &.show {
            z-index: 1055;
            transition: transform 0.3s ease-out;
            transform: translate(0, 40px);
        }
    }
}

.form-control {
    &.invalid {
        border-color: #ff0000;
        border-style: solid;
        border-width: 1px;
    }
}

app-auto-complete {
    &.invalid {
        > input {
            border-color: #ff0000;
            border-style: solid;
            border-width: 1px;
        }
    }
}

.app-footer {
    display: flex;
    flex-grow: 0;
    //min-width: 100vw;
}

.feedback {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: gray;
    border-top: 1px solid gray;

    a,
    a:visited,
    a:active,
    a:link,
    a:hover {
        margin: 20px;
        color: blue;
    }

    p {
        text-align: right;
        float: right;
    }
}

.footer-btn,
.footer-btn:active,
.footer-btn:visited,
.footer-btn:link,
.footer-btn:focus {
    background-color: gray;
    color: white;
    border-radius: 4px 4px 0 0;
    width: 60px;
    height: 24px;
    line-height: 13px;
}

.footer-btn:hover {
    background: black;
    color: white;
}

.ag-sort-indicator-icon{
    .material-icons{
        font-size: 16px !important;
    }
}